nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 1.5rem 5rem 1.5rem 5rem;
  z-index: 10;
}

nav.bg {
  background: var(--black);
  transition: .4s ease;
}

.navlinks {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.navlinks li {
  display: inline-block;
}

.navlinks li:not(:last-child) {
  margin-right: 1.4rem;
}

.navlinks li a {
  font-family: var(--sec-font);
  font-size: 2.5rem;
}

.navlinks li a.link {
	color: var(--sec-white);
}

.login-btn,
.signUp-btn,
.signOut-btn {
  padding: 0.5rem 1.2rem;
}

.login-btn a {
  color: var(--white);
}
.signOut-btn a {
	color: var(--white);
}

.login-btn,
.signOut-btn {
  background: var(--sec-color-main);
  color: var(--white);
  border-radius: 0.2rem;
  border: 0.2rem solid var(--sec-color-main);
}

.login-btn:hover,
.signOut-btn:hover {
  border: 0.2rem solid var(--sec-color-main);
  background: none;
  transition: 0.4s ease;
}

.login-btn:hover.login-btn a,
.signOut-btn:hover.signOut-btn a {
  color: var(--sec-color-main);
  transition: 0.4s ease;
}

.signUp-btn {
  border: 0.2rem solid var(--sec-color-main);
  border-radius: 0.2rem;
}

.signUp-btn:hover {
  background: var(--sec-color-main);
  transition: 0.4s ease;
}
.signUp-btn:hover.signUp-btn a {
  color: var(--white);
  transition: 0.4s ease;
}

.signUp-btn a {
  color: var(--sec-color-main);
}

/* RESPONSIVE */
@media screen and (max-width: 700px) {
  nav {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }
}
@media screen and (max-width: 550px) {
  .navlinks li a {
    font-size: 1.5rem;
  }
}
