#amendMembership {
	width: 100%;
}

.amendMembership-showcase {
  width: 100%;
  height: fit-content;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../../images/membership-bg.png) no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
  padding: 10rem 2rem 5rem 2rem;
	margin-bottom: 3rem;
  color: var(--sec-white);
  position: relative;
}

.amendMembership-details {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: var(--white);
}

.amendMembership-details h5 {
  font-family: var(--pry-font);
  font-size: 4.5rem;
  font-weight: 400;
  text-transform: capitalize;
  text-shadow: var(--text-shadow);
}

.amendMembership-details .para-details-one {
  font-size: 2.3rem;
  font-weight: 400;
  width: 100%;
  margin: 2rem auto;
}

/* RESPONSIVE */
@media screen and (max-width: 950px) {
	.amendMembership-details h5 {
		font-size: 3rem;
	}

	.amendMembership-details .para-details-one {
		font-size: 1.6rem;
		width: 95%;
	}
}