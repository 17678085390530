#membership {
  width: 100%;
  font-family: var(--sec-font);
  line-height: 1.3;
}

.membership-showcase {
  width: 100%;
  height: 110vh;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(../../images/membership-bg.png) no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
  padding: 10rem 5rem 1rem 5rem;
  color: var(--sec-white);
  position: relative;
}

.membership-showcase h4 {
  font-family: var(--pry-font);
  font-size: 8rem;
  font-weight: 800;
  text-transform: capitalize;
  color: var(--white);
  margin-bottom: 2rem;
}

.membership-showcase .para-one,
.membership-showcase .para-two {
  font-size: 2.5rem;
  font-weight: 500;
}

.membership-showcase .para-one {
  width: 100%;
  margin-bottom: 1.2rem;
}

.membership-showcase .para-two {
  width: 60%;
}

.membership-showcase .showcase-chevron {
  position: absolute;
  left: 50%;
  bottom: 5rem;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.membership-showcase .showcase-chevron span {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--sec-color-main);
  text-transform: capitalize;
  text-align: center;
}
.membership-showcase .showcase-chevron p {
  font-size: 7rem;
  font-weight: bold;
  color: var(--sec-color-main);
  animation: bounce 1s 0.3s infinite;
}

.membership-details {
  padding: 2rem 2rem 4rem 2rem;
  width: 75%;
  margin: 0 auto;
  text-align: center;
  color: var(--sec-black-sub);
}

.membership-details h5 {
  font-family: var(--pry-font);
  font-size: 4.5rem;
  font-weight: 400;
  text-transform: capitalize;
  text-shadow: var(--text-shadow);
}

.membership-details .para-details-one {
  font-size: 2.3rem;
  font-weight: 400;
  width: 80%;
  margin: 2rem auto;
}

.membership-form {
  width: 70%;
  margin: 0 auto 4rem auto;
  background: #f5fffa;
  border-radius: 0.4rem;
  padding: 2rem;
  box-shadow: var(--box-shadow);

  display: flex;
  flex-direction: column;
}

.form-header {
  font-family: var(--pry-font);
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 400;
  text-shadow: var(--text-shadow);
  text-align: center;
  margin-bottom: 5rem;
  letter-spacing: 0.1rem;
}

.input-container-box-1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.8rem;
}

.input-container-box-1 .input-box {
  width: 50%;
}

.input-container-box-1 .input-box:first-of-type {
  margin-right: 0.5rem;
}

.input-container-box-1 .input-box:last-of-type {
  margin-left: 0.5rem;
}

.input-container-box-1 .input-box label,
.input-container-box-2 label,
.input-container-box-3 label,
.input-container-box-4 label,
.ant-form-item-required {
  display: inline-block;
  margin-bottom: 0.4rem;
  text-transform: capitalize;
  font-family: var(--sec-font);
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 0.1rem;
  color: var(--sec-black-main);
}

.input-container-box-1 .input-box input,
.input-container-box-3 input {
  width: 100%;
  display: inline-block;
  padding: 0.8rem 0rem.8rem 0.5rem;
  color: var(--sec-black-sub);
  font-size: 1.5rem;
  outline: none;
  border: 0.2rem solid var(--border-grey);
  border-radius: 0.4rem;
}

.input-container-box-2,
.input-container-box-3,
.input-container-box-4,
.form-contact {
  margin-bottom: 1.8rem;
}

.input-container-box-2 .input-box {
  margin-bottom: 0.6rem;
  width: fit-content;
  display: flex;
  align-items: center;
}

.input-container-box-2 .input-box span {
  display: inline-block;
  font-size: 1.4rem;
  text-transform: capitalize;
  margin-left: 0.5rem;
}

.input-container-box-2 .input-box input[type="radio"] {
  display: block;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  padding: 0;
  width: 1.6rem;
  height: 1.6rem;
  border: 0.1rem solid var(--border-grey);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
.input-container-box-2 .input-box input[type="radio"]::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  display: inline-block;
  content: "";
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transition: 0.2s transform ease;
  background: var(--border-grey);
}
.input-container-box-2 .input-box input[type="radio"]:checked::before {
  transform: translate(-50%, -50%) scale(1);
}

.input-container-box-2 .input-box input[type="date"] {
  display: inline-block;
  background-color: var(--sec-black-sub);
  font-family: var(--sec-font);
  font-size: 1.4rem;
  color: var(--white);
  border: 0;
  padding: 0.5rem;
  outline: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.input-container-box-3,
.input-container-box-4 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-container-box-4 textarea {
  resize: none;
  width: 100%;
  height: 10rem;
  border: 0.2rem solid var(--border-grey);
  border-radius: 0.4rem;
  outline: none;
  padding: 0.4rem;
  font-size: 1.5rem;
  color: var(--sec-black-sub);
}

.ant-form-item {
  margin-bottom: 1.8rem;
}
.ant-btn.css-dev-only-do-not-override-fpg3f5.ant-btn-default {
  border: 0.2rem solid var(--sec-black-sub);
  background: var(--sec-black-sub);
  color: var(--white);
}
.ant-btn.css-dev-only-do-not-override-fpg3f5.ant-btn-default:hover {
  background: none;
  color: var(--sec-black-sub);
  border: 0.2rem solid var(--sec-black-sub);
}
.ant-row.ant-form-item-row.css-dev-only-do-not-override-fpg3f5 {
  flex-direction: column;
}
.ant-col.ant-form-item-label.css-dev-only-do-not-override-fpg3f5 {
  text-align: start;
}

.form-contact h4 {
  display: inline-block;
  margin-bottom: 0.4rem;
  text-transform: capitalize;
  font-family: var(--sec-font);
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: var(--sec-black-main);
}

.form-contact p {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: var(--sec-font);
  line-height: 1.3;
  color: var(--sec-black-main);
  letter-spacing: 0.1rem;
}

.form-btn {
  border: 0.1rem solid #000;
  text-align: center;
  width: 50%;
  align-self: center;
  border: 0.2rem solid var(--sec-color-sub);
  background: var(--sec-color-sub);
  color: var(--white);
  padding: 1.2rem 0;
  font-size: 1.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
}
.form-btn:hover {
  background: none;
  color: var(--sec-color-sub);
  border: 0.2rem solid var(--sec-color-sub);
  transition: 0.4s ease;
}

.input-container-box-2 label a {
  color: var(--sec-color-sub);
  text-shadow: var(--text-shadow);
  text-decoration: underline;
  font-weight: bold;
}

/* RESPONSIVE */
@media screen and (max-width: 950px) {
  .membership-showcase {
    height: 80vh;
    padding: 10rem 2rem 1rem 2rem;
  }

  .membership-showcase h4 {
    font-size: 5rem;
  }

  .membership-showcase .para-one,
  .membership-showcase .para-two {
    font-size: 1.6rem;
  }

  .membership-showcase .para-two {
    width: 80%;
  }

  .membership-showcase .showcase-chevron span {
    font-size: 2rem;
  }

  .membership-showcase .showcase-chevron p {
    font-size: 5rem;
  }

  .membership-form {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .membership-details h5 {
    font-size: 3rem;
  }

  .membership-details .para-details-one {
    font-size: 1.8rem;
    width: 100%;
  }

  .form-btn {
    width: 60vw;
  }
}

@media screen and (max-width: 800px) {
  .membership-form {
    width: 90%;
  }

  .form-header {
    font-size: 1.6rem;
  }

  .membership-details {
    width: 100%;
  }

  .membership-details h5 {
    font-size: 2.4rem;
  }
  .membership-details .para-details-one {
    font-size: 1.5rem;
  }

  .input-container-box-1 .input-box label,
  .input-container-box-2 label,
  .input-container-box-3 label,
  .input-container-box-4 label,
  .ant-form-item-required {
    font-size: 1.2rem;
  }

  .input-container-box-1.income {
    flex-direction: column;
    align-items: flex-start;
  }

  .input-container-box-1.income .input-box {
    width: 100%;
  }

  .input-container-box-1.income .input-box:first-of-type,
  .input-container-box-1.income .input-box:last-of-type {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.8rem;
  }

  .input-container-box-2 .input-box span {
    font-size: 1.2rem;
  }

  .input-container-box-2 .input-box input[type="date"] {
    padding: 1rem;
  }

  .ant-form-item .ant-form-item-label > label {
    font-size: 1.2rem;
  }

  .ant-form-item .ant-form-item-label {
    padding-bottom: 0;
  }

  .form-contact p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 550px) {
  .membership-showcase {
    height: 65vh;
  }

  form {
    width: 95vw;
  }
}
