#gradient-line {
  width: 20rem;
  margin-bottom: 2rem;
  height: .3rem;
  background: linear-gradient(
    to right,
    rgb(255, 200, 87),
    rgb(233, 114, 76),
    rgb(197, 40, 61),
    rgba(233, 233, 233, 0.4)
  );;
  border-radius: .3rem;
}

/* RESPONSIVE */
@media screen and (max-width: 700px) {
  #gradient-line {
    width: 15rem;
  }
}