footer {
  width: 100%;
  background-color: var(--sec-black-sub);
  font-family: var(--sec-font);
}

.footer-center {
  color: var(--sec-white);
  max-width: 80%;
  margin: 0 auto;
  padding: 3rem 0;
  display: flex;
}

.footer-center > *:not(:last-child) {
  margin-right: 4rem;
}

.footer-center-box.logo .footer-center-box-header {
	display: inline-block;
  font-family: var(--pry-font);
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 1.2;
	color: var(--sec-white);
}

.footer-center-box.logo p {
  font-size: 1.2rem;
  font-weight: 400;
	width: 22rem;
	line-height: 1.6;
}

.footer-center-box .footer-center-box-header {
	font-size: 2rem;
	font-weight: 400;
	text-transform: capitalize;
	margin-bottom: 1.2rem;
}

.footer-center-box ul {
	list-style: none;
}

.footer-center-box ul li:not(:last-of-type) {
	margin-bottom: .8rem;
}

.footer-center-box ul li a {
	color: var(--white);
	font-size: 1.2rem;
	font-weight: 300;
}

.footer-center-box ul li p {
	font-size: 1.2rem;
	font-weight: 300;
}

/* RESPONISVE */
@media screen and (max-width: 1170px) {
	.footer-center {
		max-width: 100%;
		padding: 3rem 1.5rem;
	}
}

@media screen and (max-width: 700px) {
	.footer-center {
		flex-direction: column;
		padding: 3rem;
	}

	.footer-center > *:not(:last-child) {
		margin-right: 0;
		margin-bottom: 2.5rem;
	}
}
