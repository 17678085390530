#landing-page {
  width: 100%;
  font-family: var(--sec-font);
}

.landing-page-showcase {
  width: 100%;
  height: 110vh;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(../../images/landing-bg.png) no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
  padding: 10rem 5rem 1rem 5rem;
  color: var(--sec-white);
  position: relative;
}

.landing-page-showcase .header {
  margin-bottom: 0.8rem;
}

.landing-page-showcase .header p {
  font-family: var(--pry-font);
  font-size: 8rem;
  font-weight: 800;
  text-transform: capitalize;
  color: var(--white);
}

.para-one,
.para-two {
  font-size: 2.5rem;
  font-weight: 500;
  width: 40rem;
}

.para-one {
  margin-bottom: .8rem;
}

.landing-page-showcase button {
  display: inline-block;
  margin: 1.5rem 0 3rem 0;
  text-transform: capitalize;
  font-size: 2.5rem;
  background: linear-gradient(
    rgba(217, 217, 217, 0.4),
    rgba(217, 217, 217, 0.1)
  );
  color: var(--white);
  padding: 0.5rem 1rem;
  font-family: var(--sec-font);
  font-weight: 300;
}

.border-gradient {
  border: 0.1rem solid;
  border-image-slice: 1;
  border-width: 0.3rem;
}
.border-gradient-color {
  border-image-source: linear-gradient(
    to left,
    rgb(255, 200, 87),
    rgb(233, 114, 76),
    rgb(197, 40, 61),
    rgba(233, 233, 233, 0.4)
  );
}

.landing-page-showcase .showcase-chevron {
  position: absolute;
  left: 50%;
  bottom: 5rem;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page-showcase .showcase-chevron span {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--sec-color-main);
  text-transform: capitalize;
}
.landing-page-showcase .showcase-chevron p {
  font-size: 7rem;
  font-weight: bold;
  color: var(--sec-color-main);
  animation: bounce 1s 0.3s infinite;
}

/* Chevron Animation */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-2rem);
  }
  20% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.landing-page-details {
  max-width: 80%;
  margin: 5rem auto;
}

.landing-page-details .header {
  text-align: center;
  margin-bottom: 3.5rem;
}

.landing-page-details .header h3 {
  font-family: var(--pry-font);
  text-transform: capitalize;
  font-size: 4.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.landing-page-details .header p {
  font-family: var(--sec-font);
  font-size: 2.5rem;
  font-weight: 400;
}

.landing-page-details_steps-box {
  width: 90%;
  margin: 0 auto 2.5rem auto;
  display: flex;
  align-items: center;
  gap: 5rem;
}

.landing-page-details_steps-box.reverse {
  flex-direction: row-reverse;
}

.landing-page-details_img-box {
  width: 30vw;
  position: relative;
  border-radius: 50%;
}

.landing-page-details_img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.landing-page-details_img-box-icon {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--white);
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.landing-page-details_img-box-icon_bg,
.landing-page-details_img-box-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.landing-page-details_img-box-icon_bg {
  background: var(--sec-color-main);
  width: 60%;
  height: 60%;
  border-radius: 50%;
  opacity: 60%;
  z-index: 1;
}

.landing-page-details_img-box-icon svg {
  width: 60%;
  height: 60%;
  z-index: 2;
  stroke: #0f0f0f;
}

.landing-page-details_steps-box-texts {
  width: 40rem;
}

.landing-page-details_steps-box-texts h4 {
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--sec-color-sub);
}

.landing-page-details_steps-box-texts h6 {
  margin: 1rem 0 1.5rem 0;
  color: var(--sec-black-main);
  font-size: 2.5rem;
  font-weight: 400;
}

.landing-page-details_steps-box-texts p {
  font-size: 2rem;
  font-weight: 300;
  color: var(--sec-black-main);
}

.no-bg-btn-container {
  margin-bottom: 5rem;
  text-align: center;
}

button.no-bg {
  display: inline-block;
  padding: 0.5rem 5rem;
  text-transform: capitalize;
  color: var(--sec-black-main);
  font-size: 2rem;
}

/* RESPONSIVE */
@media screen and (max-width: 950px) {
  .landing-page-showcase {
    height: 80vh;
  }

  .landing-page-showcase .showcase-chevron p {
    font-size: 5rem;
  }
}
@media screen and (max-width: 700px) {
	.landing-page-details {
		max-width: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.landing-page-details .header h3 {
		font-size: 3rem;
	}

	.landing-page-details .header p {
		font-size: 1.5rem;
	}
  .landing-page-details_steps-box {
    flex-direction: column-reverse;
  }

  .landing-page-details_steps-box.reverse {
    flex-direction: column-reverse;
  }

	.landing-page-details_steps-box-texts {
		width: 85vw;
	}

	.landing-page-details_steps-box-texts h4 {
		font-size: 2rem;
	}

	.landing-page-details_steps-box-texts h6 {
		font-size: 1.8rem;
	}

	.landing-page-details_steps-box-texts p {
		font-size: 1.5rem;
		width: 100%;
	}

	.landing-page-details_img-box {
		width: 50vw;
	}

	.landing-page-details_img-box-icon {
		width: 18vw;
		height: 18vw;
	}

	.landing-page-showcase {
		padding: 10rem 2rem 1rem 2rem;
	}

	.landing-page-showcase .header p {
		font-size: 5rem;
	}

	.para-one, .para-two {
		font-size: 1.6rem;
		width: 75vw;
	}

	.landing-page-showcase button {
		font-size: 1.6rem;
		margin: .5rem 0 1.8rem 0;
	}

	.landing-page-showcase .showcase-chevron span {
		font-size: 2rem;
	}

	button.no-bg {
		padding: 1rem 3rem;
	}
}

@media screen and (max-width: 550px) {
  .landing-page-showcase {
    height: 65vh;
  }
}

