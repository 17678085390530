/* style for token-header */
.token-header {
	width: 30rem;
	margin: 5rem auto;
	box-shadow: var(--box-shadow);
	text-align: center;
	height: 10rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-size: 2rem;
	font-weight: bold;
}

/* end style for token-header */
#updatePassword {
	width: 100%;
	font-family: var(--sec-font);
}

.updatePassword-content {
	width: 100%;
  height: 100vh;
  color: var(--sec-black-main);
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.updatePassword-content-image {
	height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(../../images/login-img.png) no-repeat;
  background-position: top right;
  background-size: cover;
}

.updatePassword-content-text {
	padding: 12rem 3rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.updatePassword-content-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 4rem;
}

.updatePassword-content-text_header h2 {
	font-family: var(--pry-font);
  font-size: 4.5rem;
  font-weight: 400;
  text-shadow: var(--text-shadow);
  text-transform: capitalize;
  letter-spacing: 0.1rem;
}

.updatePassword-content-text_header p {
	font-size: 2rem;
  font-weight: 400;
  margin: 1.5rem auto 2rem auto;
  letter-spacing: 0.1rem;
	text-align: center;
}

.updatePassword-content form {
	width: 40rem;
	margin:  0 auto;
	display: flex;
	flex-direction: column;
}

.updatePassword-content form input {
	display: inline-block;
  width: 100%;
  padding: 1.2rem 0 1.2rem 1rem;
  border: 0.2rem solid var(--border-grey-opacity);
  outline: none;
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--sec-black-main);
	margin-bottom: 1.5rem;
}

.updatePassword-content form input::placeholder {
	text-transform: capitalize;
  font-size: 1.5rem;
  font-family: var(--sec-font);
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.updatePassword-content form button {
	display: inline-block;
  width: 100%;
  padding: 0.9rem 0 0.9rem 0.4rem;
  border: 0.2rem solid var(--sec-color-sub);
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  color: var(--white);
  background: var(--sec-color-sub);
}

.updatePassword-content form button:hover {
	border: 0.2rem solid var(--sec-color-sub);
  background: transparent;
  color: var(--sec-color-sub);
	transition: .4s ease;
}

.updatePassword-content-register {
	margin: 2rem 0 3rem 0;
	display: flex;
	align-items: center;
	width: 40rem;
}

.updatePassword-content-register span {
	display: inline-block;
	text-transform: capitalize;
	margin-right: 1.5rem;
	font-size: 1.4rem;
}

.updatePassword-content-register p {
	font-weight: 700;
	font-size: 1.5rem;
	text-decoration: underline;
	text-transform: capitalize;
	color: var(--sec-color-sub);
}

.updatePassword-content-redirect {
	display: flex;
	align-items: center;
	width: 40rem;
}

.updatePassword-content-redirect span {
	display: inline-block;
	font-size: 1.4rem;
	margin-right: .4rem;
}

.updatePassword-content-redirect p {
	text-transform: capitalize;
	font-size: 1.5rem;
	font-weight: 700;
	color: var(--sec-color-sub);
}

.updatePassword-content-register p:hover,
.updatePassword-content-redirect p:hover {
	letter-spacing: .1rem;
	transition: all .4s ease-out;
}

/* RESPONSIVE */
@media screen and (max-width: 1200px) {
	.updatePassword-content-text_header h2 {
		font-size: 3.8rem;
	}
}
@media screen and (max-width: 900px) {
	.updatePassword-content {
		grid-template-columns: 1fr;
		place-items: center;
		background: linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,.6)), url('../../images/login-img.png') no-repeat;
		background-size: cover;
		background-position: top center;
	}
	.updatePassword-content-image {
		display: none;
	}
	.updatePassword-content-text {
		width: 95%;
		margin: 0 auto;
		box-shadow: var(--box-shadow);
		padding: 5rem 1.5rem 5rem 1.5rem;
		background-color: var(--white);
	}

	.updatePassword-content form {
		width: 80%;
	}
	.updatePassword-content-register,
	.updatePassword-content-redirect {
		width: 80%;
	}
}

@media screen and (max-width: 550px) {
	.updatePassword-content-text_header h2 {
		font-size: 3rem;
		text-align: center;
	}
	.updatePassword-content-text_header p {
		font-size: 1.5rem;
	}

	.updatePassword-content form {
		width: 90%;
	}
	.updatePassword-content-register,
	.updatePassword-content-redirect {
		width: 90%;
	}
}