#about-us {
  width: 100%;
  font-family: var(--sec-font);
  line-height: 1.3;
}

.about-us-showcase {
  width: 100%;
  height: 110vh;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(../../images/aboutUs-bg.png) no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
  padding: 10rem 5rem 1rem 5rem;
  color: var(--sec-white);
  position: relative;
}

.about-us-showcase h4 {
  font-family: var(--pry-font);
  font-size: 8rem;
  font-weight: 800;
  text-transform: capitalize;
  color: var(--white);
  margin-bottom: 2rem;
}

.about-us-showcase .para-one,
.about-us-showcase .para-two {
  font-size: 2.5rem;
  font-weight: 500;
}

.about-us-showcase .para-one {
  width: 100%;
  margin-bottom: 1.2rem;
}

.about-us-showcase .para-two {
  width: 60%;
}

.about-us-showcase .showcase-chevron {
  position: absolute;
  left: 50%;
  bottom: 5rem;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-showcase .showcase-chevron span {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--sec-color-main);
  text-transform: capitalize;
  text-align: center;
}
.about-us-showcase .showcase-chevron p {
  font-size: 7rem;
  font-weight: bold;
  color: var(--sec-color-main);
  animation: bounce 1s 0.3s infinite;
}

.about-us-honour {
  display: flex;
  align-items: center;
  width: 70%;
  margin: 2.5rem auto;
}

.about-us-honour-text {
  width: 50%;
	color: var(--sec-black-main);
}

.about-us-honour-text h4 {
  font-family: var(--pry-font);
  font-size: 4rem;
  font-weight: 400;
  text-shadow: var(--text-shadow);
  margin-bottom: 1rem;
	text-transform: capitalize;
}

.about-us-honour-text .para-one,
.about-us-honour-text .para-two {
  font-size: 2rem;
  font-weight: 400;
  width: 100%;
}

.about-us-honour-img {
  width: 35rem;
  margin-left: auto;
}

.about-us-honour-img img {
  width: 100%;
  object-fit: cover;
}

.about-us-honour:last-of-type {
	flex-direction: row-reverse;
	padding: 4rem 0 6rem 0;
}

.about-us-honour:last-of-type .about-us-honour-img{
	margin: auto;
}

.about-us-honour:last-of-type .about-us-honour-text h4 {
	margin-bottom: .1rem;
}

.about-us-honour:last-of-type .about-us-honour-text .para-one {
	margin-bottom: 2rem;
}

.about-us-honour:last-of-type .about-us-honour-text .para-two {
	margin-bottom: 1.2rem;
}

.about-us-video {
	width: 70%;
	margin: 0 auto;
}

.about-us-video_text {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	font-size: 2rem;
	color: var(--sec-black-main);
}

.about-us-video_text p {
	margin-right: .5rem;
}

.about-us-video_text a {
	font-weight: 700;
	color: var(--sec-color-main);
	border-bottom: .2rem solid var(--sec-color-main);
	letter-spacing: .1rem;
}

.about-us-how {
	width: 70%;
	margin: 2.5rem auto;
	color: var(--sec-black-main);
}

.about-us-how h4 {
	font-family: var(--pry-font);
  font-size: 4rem;
  font-weight: 400;
  text-shadow: var(--text-shadow);
  margin-bottom: 1rem;
	text-transform: capitalize;
}

.about-us-how p {
	font-size: 2rem;
	text-align: justify;
}

/* RESPONSIVE */
@media screen and (max-width: 950px) {
  .about-us-showcase {
    height: 80vh;
    padding: 10rem 2rem 1rem 2rem;
  }
  .about-us-showcase h4 {
    font-size: 5rem;
  }

  .about-us-showcase .para-one,
  .about-us-showcase .para-two {
    font-size: 1.6rem;
  }

  .about-us-showcase .para-two {
    width: 80%;
  }

  .about-us-showcase .showcase-chevron span {
    font-size: 2rem;
  }

  .about-us-showcase .showcase-chevron p {
    font-size: 5rem;
  }

  .about-us-honour {
    width: 95%;
    margin: 3rem auto;
    flex-direction: column-reverse;
    align-items: center;
  }

  .about-us-honour-text {
    width: 100%;
  }

  .about-us-honour-img {
    margin: auto;
    margin-bottom: 3rem;
  }

  .about-us-honour-text h4 {
    font-size: 3rem;
  }

  .about-us-honour-text .para-one,
  .about-us-honour-text .para-two {
    font-size: 1.6rem;
  }

  .about-us-honour-text .para-one {
    margin-bottom: 1rem;
  }

	.about-us-honour:last-of-type {
	flex-direction: column-reverse;
}

	.about-us-video {
		width: 95%;
	}

	.about-us-video_text {
		font-size: 1.8rem;
	}

	.about-us-how {
		width: 95%;
	}

	.about-us-how h4 {
		font-size: 3rem;
	}

	.about-us-how p {
		font-size: 1.6rem;
	}
}

@media screen and (max-width: 700px){
  .about-us-showcase {
    height: 80vh;
  }
}

@media screen and (max-width: 550px) {
  .about-us-showcase {
    height: 65vh;
    background-position: top right;
  }
	
	.about-us-honour-img {
		width: 25rem;
	}
}
