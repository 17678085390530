#submit-container-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.submit-success {
  width: 70vw;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 1rem;
  padding: 2rem;
}

.submit-success-para-one {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.submit-success-para-two {
  font-size: 1.6rem;
  text-align: center;
  width: 70%;
  line-height: 1.3;
  margin-bottom: 2rem;
}

.submit-success button {
  background: var(--border-grey);
  color: blue;
  border: none;
  width: 25rem;
  padding: 1rem 0rem;
  color: white;
  font-size: 1.7rem;
  letter-spacing: .1rem;
}

.submit-success-register {
  margin: 2rem 0;
  display: flex;
  align-items: center;
}

.submit-success-register span {
	display: inline-block;
	text-transform: capitalize;
	margin-right: 1.5rem;
	font-size: 1.4rem;
}
.submit-success-register p {
	font-weight: 700;
	font-size: 1.5rem;
	text-decoration: underline;
	text-transform: capitalize;
	color: var(--sec-color-sub);
}

.submit-success-redirect {
	display: flex;
	align-items: center;
}

.submit-success-redirect span {
	display: inline-block;
	font-size: 1.4rem;
	margin-right: .4rem;
}

.submit-success-redirect p {
	text-transform: capitalize;
	font-size: 1.5rem;
	font-weight: 700;
	color: var(--sec-color-sub);
}

.submit-success-register p:hover,
.submit-success-redirect p:hover {
	letter-spacing: .1rem;
	transition: .4s ease-out;
}

/* RESPONSIVE */
@media screen and (max-width: 600px) {
  .submit-success {
    width: 90vw;
  }

  .submit-success-para-two {
    width: 100%;
  }
}
