#membership-success {
  width: 100%;
  font-family: var(--sec-font);
  line-height: 1.3;
}

.membership-success-showcase {
  width: 100%;
  height: 80vh;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(../../images/membership-bg.png) no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
  background-size: cover;
  padding: 10rem 5rem 1rem 5rem;
  color: var(--sec-white);
  position: relative;
}

.membership-success-showcase h4 {
  font-family: var(--pry-font);
  font-size: 8rem;
  font-weight: 800;
  text-transform: capitalize;
  color: var(--white);
  margin-bottom: 2rem;
}

.membership-success-showcase .para-one,
.membership-success-showcase .para-two {
  font-size: 2.5rem;
  font-weight: 500;
}

.membership-success-showcase .para-one {
  width: 100%;
  margin-bottom: 1.2rem;
}

.membership-success-showcase .para-two {
  width: 60%;
}

.membership-success-details {
  padding: 2rem 2rem 4rem 2rem;
  width: 75%;
  margin: 0 auto;
  text-align: center;
}

.membership-success-details h5 {
  font-family: var(--pry-font);
  font-size: 4.5rem;
  font-weight: 400;
  text-transform: capitalize;
  text-shadow: var(--text-shadow);
}

.para-details-one {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 2rem 0;
}

.para-details-two,
.para-details-three {
  font-size: 2.3rem;
  font-weight: 400;
  width: 80%;
  margin: 0 auto;
}

.para-details-three {
  margin-top: 1.5rem;
}

.para-details-three a {
  color: var(--sec-color-sub);
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (max-width: 900px) {
  .membership-success-showcase {
    padding: 10rem 2rem 1rem 2rem;
  }

  .membership-success-showcase h4 {
    font-size: 5rem;
  }

  .membership-success-showcase .para-one,
  .membership-success-showcase .para-two {
    font-size: 1.8rem;
  }

  .membership-success-details {
    width: 90%;
    color: var(--sec-black-sub);
  }

  .membership-success-details h5 {
    font-size: 2.1rem;
  }

  .para-details-one {
    font-size: 1.4rem;
  }

  .para-details-two,
  .para-details-three {
    font-size: 1.6rem;
    width: 90%;
    letter-spacing: 0.1rem;
  }
}

@media screen and (max-width: 700px) {
  .membership-success-showcase {
    height: 70vh;
  }
}

@media screen and (max-width: 550px) {
  .membership-success-showcase {
    height: 65vh;
  }
}
