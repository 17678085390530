#sign-up {
  width: 100%;
  font-family: var(--sec-font);
}

.sign-up-center {
  width: 100%;
  height: 100vh;
  color: var(--sec-black-main);
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.sign-up-text {
  padding: 12rem 3rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-up-text-header {
  font-family: var(--pry-font);
  font-size: 4.5rem;
  font-weight: 400;
  text-shadow: var(--text-shadow);
  text-transform: capitalize;
  letter-spacing: 0.1rem;
}

.sign-up-text-action {
  font-size: 2rem;
  font-weight: 400;
  margin: 1.5rem 0 2rem 0;
  letter-spacing: 0.1rem;
}

/* .google-sign-up-btn {
  padding: 1rem 4rem;
  display: flex;
  align-items: center;
  border: 0.2rem solid var(--border-grey-opacity);
  transition: 0.4s ease;
}

.google-sign-up-btn:hover {
  background: var(--sec-black-main);
  border: 0.2rem solid var(--sec-black-main);
}
.google-sign-up-btn:hover.google-sign-up-btn span {
  color: var(--white);
}

.google-sign-up-btn svg {
  font-size: 2.5rem;
}

.google-sign-up-btn span {
  display: inline-block;
  font-size: 2rem;
  margin-left: 1.3rem;
  color: var(--sec-black-main);
  letter-spacing: 0.1rem;
} */

.sign-up-text-line {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 2.5rem 0;
  position: relative;
}

.sign-up-text-line::after,
.sign-up-text-line::before {
  content: "";
  position: absolute;
  width: 14rem;
  height: 0.2rem;
  background: var(--border-grey-opacity);
  border-radius: 0.5rem;
  top: 50%;
}

.sign-up-text-line::before {
  right: 0;
  transform: translateX(-2.2rem);
}
.sign-up-text-line::after {
  left: 0;
  transform: translateX(2.2rem);
}

.sign-up-text-input {
  margin-bottom: 1.8rem;
}

.sign-up-text-input input {
  display: inline-block;
  width: 32rem;
  padding: 1.2rem 0 1.2rem 1rem;
  border: 0.2rem solid var(--border-grey-opacity);
  outline: none;
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--sec-black-main);
}

.sign-up-text-input input::placeholder {
  text-transform: capitalize;
  font-size: 1.5rem;
  font-family: var(--sec-font);
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.sign-up-text-savePassword {
  text-align: center;
  margin: 1rem 0rem 3.5rem 0rem;
}

.sign-up-text-savePassword-check {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}

.sign-up-text-savePassword-check input {
  display: inline-block;
  margin-right: 0.5rem;
  cursor: pointer;
}

.sign-up-text-savePassword-check p {
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  margin-bottom: .5rem;
}

.sign-up-text-savePassword-forgetPass {
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: underline;
}

.sign-up-text-savePassword-forgetPass:hover {
  letter-spacing: .1rem;
  transition: letter-spacing .4s ease-out;
}

.sign-up-text-btn {
  display: inline-block;
  width: 32rem;
  padding: 0.9rem 0 0.9rem 0.4rem;
  border: 0.2rem solid var(--sec-color-sub);
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  color: var(--white);
  background: var(--sec-color-sub);
  transition: 0.4s ease;
}
.sign-up-text-btn:hover {
  border: 0.2rem solid var(--sec-color-sub);
  background: transparent;
  color: var(--sec-color-sub);
}

.sign-up-text_signUp {
	margin-top: 2.6rem;
	display: flex;
	align-items: center;
}

.sign-up-text_signUp p {
	color: var(--sec-black-main);
	font-size: 2rem;
	font-weight: 400;
	margin-right: 1rem;
}

.sign-up-text_signUp a {
	color: var(--sec-black-main);
	font-size: 2rem;
	font-weight: 700;
	border-bottom: .2rem solid var(--sec-black-main);
}

.sign-up-text_signUp:hover.sign-up-text_signUp a {
	color: var(--sec-color-sub);
	border-bottom: .2rem solid var(--sec-color-sub);
	transition: .2s ease;
}

.sign-up-img {
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(../../images/login-img.png) no-repeat;
  background-position: top right;
  background-size: cover;
}

/* RESPONSIVE */
@media screen and (max-width: 900px) {
	.sign-up-img {
		display: none;
	}

	.sign-up-center {
		grid-template-columns: 1fr;
		place-items: center;
    background: linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,.6)), url('../../images/login-img.png') no-repeat;
		background-size: cover;
		background-position: top center;
	}

	.sign-up-text {
		width: 95%;
		padding: 5rem 2rem;
    background-color: var(--white);
	}
}
