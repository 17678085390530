/* GLOBAL STYLES */
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --pry-font: "Nanum Myeongjo", serif;
  --sec-font: "Raleway", sans-serif;
  --black: #000;
  --sec-black-main: #2e2e2e;
  --sec-black-sub: #1c1c1c;
  --sec-black-main_gradient: rgba(0, 0, 0, 0.5);
  --white: #fff;
  --sec-white: #e9e9e9;
  --sec-color-main: #ffc857;
  --sec-color-main_gradient: rgba(255, 199, 87, 0.53);
  --sec-color-sub: #e9724c;
  --grey_gradient: rgba(217, 217, 217, 0.5);
  --tertiary-color: #c5283d;
  --text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  --border-grey-opacity: rgba(100, 96, 96, 0.5);
  --box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  --border-grey: rgba(114, 110, 109);
}

html {
  font-size: 62.5%;
}

body {
  width: 100vw;
  overflow-x: hidden;
  font-family: var(--sec-font);
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

a {
  text-decoration: none;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
}
/* GLOBAL STYLES */
