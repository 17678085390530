#privacy-policyPage {
  width: 100%;
  font-family: var(--sec-font);
  line-height: 1.3;
}

.privacy-policyPage_showcase {
  width: 100%;
  height: 110vh;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(../../images/privacy-bg.png) no-repeat;
  background-attachment: fixed;
  background-position: bottom center;
  background-size: cover;
  padding: 10rem 5rem 1rem 5rem;
  color: var(--sec-white);
  position: relative;
}

.privacy-policyPage_showcase h4 {
  font-family: var(--pry-font);
  font-size: 8rem;
  font-weight: 800;
  text-transform: capitalize;
  color: var(--white);
  margin-bottom: 2rem;
}

.privacy-policyPage_showcase .para-one,
.privacy-policyPage_showcase .para-two {
  font-size: 2.5rem;
  font-weight: 500;
}

.privacy-policyPage_showcase .para-one {
  width: 100%;
  margin-bottom: 1.2rem;
}

.privacy-policyPage_showcase .para-two {
  width: 60%;
}

.privacy-policyPage_showcase .showcase-chevron {
  position: absolute;
  left: 50%;
  bottom: 5rem;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.privacy-policyPage_showcase .showcase-chevron span {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--sec-color-main);
  text-transform: capitalize;
  text-align: center;
}
.privacy-policyPage_showcase .showcase-chevron p {
  font-size: 7rem;
  font-weight: bold;
  color: var(--sec-color-main);
  animation: bounce 1s 0.3s infinite;
}

.privacy-policyPage_texts {
  max-width: 90%;
  margin: 3rem auto 0 auto;
  color: var(--sec-black-main);
}

.privacy-policyPage_texts-background {
  margin-bottom: 2.5rem;
}

.privacy-policyPage_texts-background h5 {
  text-transform: capitalize;
  font-family: var(--pry-font);
  font-size: 4rem;
  font-weight: 400;
  text-shadow: var(--text-shadow);
  margin-bottom: 1rem;
}

.privacy-policyPage_texts-background p {
  font-size: 2rem;
  font-weight: 400;
}

.privacy-policyPage_texts-policies-article {
  margin-bottom: 3rem;
}

.privacy-policyPage_texts-policies-article h3 {
  font-family: var(--pry-font);
  font-size: 4rem;
  font-weight: 400;
  text-shadow: var(--text-shadow);
  margin-bottom: 1.2rem;
}

.privacy-policyPage_texts-policies-article_step {
  font-size: 2rem;
  font-weight: 400;
}

.privacy-policyPage_texts-policies-article_step:not(:last-of-type) {
  margin-bottom: 2.5rem;
}

.privacy-policyPage_texts-policies-article_step-details {
  margin-bottom: 1.9rem;
}

.privacy-policyPage_texts-policies-article_step-details p {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 0.6rem;
}

/* RESPONSIVE */
@media screen and (max-width: 950px) {
  .privacy-policyPage_showcase {
    height: 80vh;
  }

  .privacy-policyPage_showcase .para-two {
    width: 80%;
  }
}
@media screen and (max-width: 700px) {
  .privacy-policyPage_showcase {
    padding: 10rem 2rem 1rem 2rem;
  }
  .privacy-policyPage_showcase h4 {
    font-size: 5rem;
  }

  .privacy-policyPage_showcase .para-one,
  .privacy-policyPage_showcase .para-two {
    font-size: 1.6rem;
  }

  .privacy-policyPage_showcase .showcase-chevron span {
    font-size: 2rem;
  }

  .privacy-policyPage_showcase .showcase-chevron p {
    font-size: 5rem;
  }

  .privacy-policyPage_texts {
    max-width: 100%;
    padding: 0 2rem;
  }

  .privacy-policyPage_texts-background h5 {
    font-size: 2.8rem;
  }

  .privacy-policyPage_texts-background p {
    font-size: 1.6rem;
    line-height: 1.4;
  }

  .privacy-policyPage_texts-policies-article h3 {
    font-size: 2.8rem;
  }

  .privacy-policyPage_texts-policies-article_step {
    font-size: 1.6rem;
    line-height: 1.4;
  }

  .privacy-policyPage_texts-policies-article_step-details p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 550px) {
  .privacy-policyPage_showcase {
    height: 65vh;
  }
}
